@import '_color-variables.scss';
.image__tile {
  display: block;
  position: relative;
  border: none;
  height: 100%;
  &:hover {
    border: none;
    cursor: pointer;
  }
  &:hover > .image__tile--title {
    background-color: $teal;
  }
  &:hover > .image__tile--img {
    filter: grayscale(1) brightness(0.5);
  }
}
.image__tile--img {
  position: relative;
  object-fit: cover;
  height: inherit;
  width: inherit;
  transition: all 0.24s ease;
  max-height: 13.9375rem;
  width: 100%;
}
@media #{$breakpoint-not-small} {
  .image__tile--img {
    max-height: none;
  }
}
.image__tile--title {
  display: inline-block;
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.75rem 1rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.88rem;
  font-weight: 800;
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
  background-color: $charcoal;
  color: white;
  transition: background-color 0.24s ease;
}
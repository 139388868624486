.flash-notification {
  margin: 1rem 1.5rem 1rem 1.5rem;
  word-wrap: break-word;

  &::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-size: 1.5rem;
    font-weight: 400;
    font-family: "Font Awesome 5 Pro";
    margin-right: 0.5rem;
  }
  &.success {
    color: #008250;
    &::before {
       content: "\f058";
    }
  }
  &.error, &.recaptcha_error {
    color: #C02425;
    &::before {
      content: "\f06a";
   }
  }
}

@media #{$breakpoint-large} {
  .flash-notification {
    margin-left: 0;
    margin-right: 0;

    &::before {
      margin-right: 1rem;
    }
  }
}
.banner__img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -3;
}

.banner__overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, transparent 0%, transparent 70%, $black-0-8 100%);
  z-index: -2;
}

.banner__location {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1rem;
  z-index: -1;
  p {
    margin: 0;
    color: white;
  }

  .banner__icon {
    display: block;
    margin-right: 0.5rem;
  }
}

@media #{$breakpoint-not-small} {
  .banner__location {
    display: flex;
  }
}

.banner--featured {
  position: relative;
}

.banner__contents--featured {
  padding: 2rem 1rem 3rem;
  background-color: $black-0-7;
}

.banner__title--featured {
  @extend .heading-one;
  margin-bottom: 1.5rem;
  color: white;
}

.banner__copy--featured {
  @extend .paragraph__large;
  margin-bottom: 2rem;
  color: white;
}

@media #{$breakpoint-not-small} {
  .banner--featured, .header-banner {
    padding: 3rem 1.5rem 4rem;
  }
  .banner--featured {
    .banner__location {
      justify-content: center;
    }
  }
  .banner__contents--featured {
    padding: 2rem 1.5rem 3rem;
  }
}

@media #{$breakpoint-large} {
  .banner--featured .banner__location {
    left: auto;
    right: 25%;
  }
  .banner__container--featured {
    max-width: 74em;
    margin-left: auto;
    margin-right: auto;
  }
  .banner__contents--featured {
    width: 50%;
    padding: 2rem 2rem 3rem;
  }

}

.header-banner {
  position: relative;
  padding: 3rem 0rem;
}

.header-banner__title {
  @extend .heading-one;
  display: inline-block;
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  color: white;
  background-color: $black-0-7;
}

.header-banner__location {
  display: none;
}

@media #{$breakpoint-not-small} {
  .header-banner{
    .content {
      position: relative;
    }

    .banner__location {
      left: auto;
      right: 1.5rem;
      bottom: -2rem;
    }

  }
}

/* This code is subject to LICENSE in root of this repository */

/* Used to detect in JavaScript if apps have loaded styles or not. */
:root {
  --reach-dialog: 1;
}

[data-reach-dialog-overlay] {
  background: rgba(0, 0, 0, 0.48);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: $dialog-zindex;
}

.definition-modal__header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
}

.definition-modal__heading {
  color: $charcoal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
}

.definition-modal__close-button {
  @extend .fas;
  @extend .fa-times;
  color: $charcoal;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.1875rem;
}

.definition-modal__close-button:hover {
  color: white;
  background-color: $teal;
  border-radius: 0.1875rem;
}

@media #{$breakpoint-large} {
  .definition-modal__detail {
    width: 50%;
  }
  .definition-modal__info {
    max-width: 40rem;
    display: flex;
  }
}
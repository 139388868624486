.tiled-list {
  padding: 0;

  li {
    padding: 0 0 1rem 0;
    margin: 0;
    list-style-type: none;
  }
}

@media #{$breakpoint-not-small} {
  .tiled-list {
    display: flex;
    flex-wrap: wrap;

    li {
      padding: 0 2rem 2rem 0;
    }
  }

  .tiled-list--2-column {
    li {
      width: 50%;
    }
  }
}
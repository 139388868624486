.article-accordian__container {
  padding-top: 0.5rem;

  .article-accordian__article-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: all 0.24s; 
    
    a {
      width: 100%;
      font-size: 1rem;
      line-height: 160%;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      color: $grey;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      transition: all 0.24s;

      i {
        padding-right: 0.375rem;
        transition: all 0.24s;
      }
    }
  }

  .current--article {
    a {
      color: $charcoal;
    }
    .tracker-body {
      background-color: $charcoal;
    }
  }

  &:hover {
    a {
      color: $charcoal;
    }

    .tracker-head, .tracker-body, .tracker-tail {
      background-color: $silver;
    }

    .tracker-hidden {
      background-color: inherit;
    }

    .article-accordian__article-item {
      &:hover {
        a {
          color: $teal;
          font-weight: bold;
          text-decoration: underline;
        }
    
        .tracker-head, .tracker-body, .tracker-tail {
          background-color: $teal;
        }

        .tracker-hidden {
          background-color: inherit;
        }
      }
    }

    .current--article {
      a {
        color: $charcoal;
      }
      .tracker-body {
        background-color: $charcoal;
      }
    }
  }

  &:focus-within {
    .article-accordian__article-item {
      a{
        color: $charcoal;
      }
  
      .tracker-head, .tracker-body, .tracker-tail {
        background-color: $silver;
      }

      .tracker-hidden {
        background-color: inherit;
      }

      button {
        color: $charcoal;
      }


      &:focus-within {
        a{
          color: $teal;
          font-weight: bold;
        }
    
        .tracker-head, .tracker-body, .tracker-tail {
          background-color: $teal;
        }

        .tracker-hidden {
          background-color: inherit;
        }
      }
    }

    .current--article {
      a {
        color: $charcoal;
      }
      .tracker-body {
        background-color: $charcoal;
      }
    }
  }
}
.quote {
  position: relative;
}

.quote--banner {
  position: relative;
  height: 16rem;
  width: 100%;
  object-fit: cover;
}

.quote--content {
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  padding: 0 1rem;
}

.quote--copy,
.quote--author {
  @extend .paragraph__regular;
  color: white;
  font-style: italic;
  margin: 0 auto;
  max-width: 33.69rem;
}

.quote--copy {
  font-weight: 700;
  margin-bottom: 1rem;
}

.quote--author {
  text-align: right;
}

@media #{$breakpoint-not-small} {
  .quote--banner {
    height: 16rem;
  }
  .quote--copy,
  .quote--author {
    font-size: 1.13rem;
  }

}

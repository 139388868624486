.three-column__featured {
  width: 100%;
}
.three-column__featured {
  .three-column__featured--container {
    padding-bottom: 1rem;
  }
}
@media #{$breakpoint-not-small} {
  .three-column__featured {
    display: flex;
    flex-wrap: wrap;
  }
  .three-column__featured {
    .three-column__featured--container {
      width: calc( 100% / 3);
    }
    & > div.three-column__featured--container:nth-child(3n+1) {
      padding-left: 0;
    }
    & > div.three-column__featured--container:nth-child(3n+3) {
      padding-right: 0;
    }
  }
}
@media #{$breakpoint-medium} {
  .three-column__featured {
    .three-column__featured--container {
      padding: 0 0.75rem;
      min-height: 10.75rem;
      max-height: 15.3125rem;
    }
  }

}
@media #{$breakpoint-large} {
  .three-column__featured {
    .three-column__featured--container {
      padding: 0 1rem;
      height: 15.3125rem;
    }
  }
}
.tile {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem 2rem;
  border: solid 1px $grey;
  border-radius: 0.19rem;
  transition: border 0.24s ease;
  &:hover {
    border: solid 1px $teal;
  }
}

.tile__icon {
  height: 2rem;
  width: 2rem;
  margin-bottom: 1rem;
}

.tile__title {
  @extend .subheading;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

.tile__contents {
  @extend .paragraph__regular;
  margin-bottom: 1rem;
}

.tile__cta {
  align-self: flex-end;
  &.contained-button {
    margin-top: 1rem;
    align-self: flex-start;
  }
}

.tile__social--link {
  color: $teal;

  &:hover{
    text-decoration: underline;
    color: $dark-teal;
  }
}

@media #{$breakpoint-not-small} {
  .tile {
    padding: 1.5rem 2rem 2rem;
  }
}

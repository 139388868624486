.content__collaborator {
  color: $charcoal;
  p {
    line-height: 1.6;
  }
}

.collaborator{
  width: 100%;
  list-style-type: none;

  &:last-child{
    padding-bottom: 2rem;
  }

  li {
    padding-bottom: 1rem;
  }
}

.collaborator_links {
  list-style-type: none;
}

@media #{$breakpoint-large} {
  .collaborator{
    width: 25%;

    &:nth-child(4n) {
      padding-right: 0;
    }
  }
}

@media #{$breakpoint-medium} {
  .collaborator{
    width: calc(100% / 3);

    &:nth-child(3n) {
      padding-right: 0;
    }
  }
}

@media #{$breakpoint-not-small} {
  .collaborator {
    li {
      padding-right: 2rem;
    }
  }
}

@import '_color-variables.scss';

.teal {
  color: $teal;
}

.dark-teal {
  color: $dark-teal;
}

.light-teal {
  color: $light-teal;
}

.grey {
  color: $grey;
}

.light-grey {
  color: $light-grey;
}

.charcoal {
  color: $charcoal;
}

.bg-charcoal {
  background-color: $charcoal;
}

.peach {
  color: $peach;
}

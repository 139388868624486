$max-page-width: 70em;

.container, .content {
  max-width: $max-page-width;
  margin-top: 3rem;
}

.container--toast {
  max-width: $max-page-width;
  margin-left: auto;
  margin-right: auto;
}

@media #{$breakpoint-not-small} {
  .container, .content {
    margin-top: 4rem;
  }
}
.card {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background: #FAFAFA;
  border: 1px solid #E0E0E0;
  min-width: 246px;
  transition: box-shadow 0.24s ease-in-out;

  i {
    color: $teal;
  }

  h2 {
    font-weight: bold;
    font-size: 1.25em;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: $dark-teal;
    margin-bottom: 1rem;
  }

  h3 {
    margin: 1rem 0;
    color: $charcoal;
  }

  p {
    font-size: 1em;
    line-height: 160%;
    color: $charcoal;
    margin-bottom: 1rem;
  }

  img {
    object-fit: contain;
  }

  .card__cta {
    font-weight: bold;
    font-size: 1em;
    line-height: 22px;
    color: $teal;
    margin-top: auto;

    i {
      transition: transform 0.24s ease-in;
    }
  }

  &:hover, &:focus {
    box-shadow: 0 0 3px #E0E0E0;

    .card__cta {
      color: $dark-teal;
    }
  }

  &:focus {
    border: 1px solid $dark-teal;
  }

  &:hover .card__cta i {
    transform:  translateX(9px);
    color: $dark-teal;
  }
}

.card--transparent {
  background-color: transparent;
}

.card--sidebar {
  flex-wrap: wrap;
  gap: 1rem;

  p {
    margin-bottom: 0;
  }
}
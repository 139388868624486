.max-width-profile-pg {
  max-width: 80rem;
}
// Materialize overrides for form
.override-placeholder > input::placeholder,
.override-placeholder > textarea::placeholder {
  color: $charcoal !important;
  opacity: 1 !important;
}
.override-placeholder > input:focus,
.override-placeholder > textarea:focus {
  outline: 2px solid $teal;
}
.override-placeholder > input,
.override-placeholder > textarea,
.override-placeholder > select
 {
  &:hover,
  &:active {
    outline: 2px solid $teal;
  }
}
.gray-default > input::placeholder {
  color: $charcoal !important;
  opacity: 1 !important;
}


input {
  &::placeholder{
    opacity: 1;
  }

  border: none;
}

.required-input::after {
  content:"*";
  color: red;
  padding-left: 0.25rem;
}

.feedback-label {
  letter-spacing: 1px;
}

select::-ms-expand {
  display: none;
}

.form-input--text {
  margin-top: 0.5rem;
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  line-height: 1.6rem;
  border-radius: 3px;
}

.form-input--select_container {
  margin-top: 0.5rem;
  display: flex;
  position: relative;
}

.form-input--select {
  line-height: 1.6rem;
  padding: 1rem;
  display: block;
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
}
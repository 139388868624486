@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

@import '_color-variables.scss';

$max-reading-width: 40rem;

body {
  font-family: 'Open Sans', sans-serif;
}
h1,h2,h3,h4,p {
  margin: 0;
  text-align: left;
}
.heading-one {
  font-size: 2.25rem;
  @media #{$breakpoint-not-small} {
    font-size: 3rem;
  }
}
.heading-two {
  font-size: 1.75rem;
  @media #{$breakpoint-not-small} {
    font-size: 2.25rem;
  }
}
.heading-three {
  font-size: 1.25rem;
  @media #{$breakpoint-not-small} {
    font-size: 1.75rem;
  }
}
.heading-four {
  font-size: 1.13rem;
  margin-bottom: 1rem;
  @media #{$breakpoint-not-small} {
    font-size: 1.25rem;
  }
}
.heading-one,
.heading-two,
.heading-three {
  margin-bottom: 2rem;
}
.heading-one,
.heading-two,
.heading-three,
.heading-four,
.subheading {
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.03125rem;
  color: $charcoal;
}
.subheading {
  font-size: 1.13rem;
  margin-bottom: 1rem;
  line-height: 1.5;
}
.paragraph__large,
.paragraph__large--bold,
.paragraph__regular,
.paragraph__regular--bold,
.paragraph__regular--bold--warning,
.paragraph__regular--extrabold {
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: $charcoal;
}
.paragraph__large {
  font-size: 1.13rem;
}
.paragraph__large--bold {
  font-size: 1.13rem;
  font-weight: 600;
  margin-bottom: 0;
  display: block;
}
.paragraph__regular {
  font-size: 1rem;
}
.paragraph__regular--bold {
  font-weight: 600;
  margin-bottom: 0;
  display: block;
}
.paragraph__regular--bold--warning {
  font-weight: 600;
  margin-bottom: 0;
  color: red;
}
.paragraph__width {
  max-width: $max-reading-width;
}
ul {
  margin: 0;
  padding-bottom: 1rem;
  li {
    padding-left: 1rem;
    margin-bottom: 0.5rem;
  }
}
a {
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
}

// NOT SMALL
@media #{$breakpoint-not-small} {
  .mb-6-rem-ns{
    margin-bottom: 6rem;
  }
  .mt-1-75rem-ns {
    margin-top: 1.75rem;
  }
}

//  Medium
@media #{$breakpoint-medium} {
  .mt-1-5rem-m {
    margin-top: 1.5rem;
  }
  .mt-3rem-m {
    margin-top: 3rem;
  }
  .w-two-thirds-m{
    width: calc((100% / 3) * 2);
  }

  .pr1-5rem-m {
    padding-right: 1.5rem;
  }
}
// Large
@media #{$breakpoint-large} {
  .mt-5rem-l {
    margin-top: 5rem;
  }

  .pb-3rem-l {
    padding-bottom: 3rem;
  }

  .w-two-thirds-l{
    width: calc((100% / 3) * 2);
  }

  .w-22rem-l {
    width: 22rem;
  }
}

$checkmark-size: 1.5rem;

.checkbox {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  line-height: 1.6rem;

  & input[type="checkbox"] {
    display: none;

    & + label:before {
      @extend .fas;
      font-size: 0.75rem;
      width: $checkmark-size;
      height: $checkmark-size;
      line-height: $checkmark-size;
      content: "\f00c";
      box-sizing: border-box;
      border: solid 0.125rem $charcoal;
      border-radius: 0.25rem;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      background-color: transparent;
      transition: background-color ease-in-out 0.16s;
    }

    &:hover + label:before {
      border-color: $charcoal !important;
      background-color: transparentize($color: $teal, $amount: 0.75);
    }

    &:checked + label:before {
      background-color: $teal;
      border-color: $teal;
    }
  }

  & label {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color ease-in-out 0.16s;
  }
}
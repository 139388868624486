.tracker-container {
  width: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
}

.tracker-head {
  display: flex;
  flex: 1 1 auto;
  width: 0.125rem;
  background-color: $white-smoke;
}

.tracker-body {
  display: block;
  width: 0.7rem;
  height: 0.7rem;
  background-color: $white-smoke;
  border-radius: 50%;
}

.tracker-tail {
  display: flex;
  flex: 1 1 auto;
  width: 0.125rem;
  background-color: $white-smoke;
}

.tracker-hidden {
  background-color: inherit;
}
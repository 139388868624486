.list {
  padding: 0;
  
  li {
    padding-left: 0;
  }
}

.list--short-text {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  li {
    margin-bottom: 1rem;
    width: 50%;
  }
}

.list--horizontal >li {
  margin-bottom: 2rem;
}

@media #{$breakpoint-not-small} {
  .list--short-text {
    display: block;

    li {
      width: auto;
    }
  }

  .list--horizontal {
    display: flex;

    li {
      margin-right: 1rem;
    }
  }
}
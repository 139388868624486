.container--about {
  max-width: 49.125rem;
  color: $charcoal;
  margin-bottom: 6rem;

  h2 {
    @extend .heading-two;
    margin-top: 2rem;

    &:first-child {
      margin-top: 0;
    }

    @media #{$breakpoint-large} {
      margin-top: 3rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  h3 {
    margin-top: 1.5rem;
    @extend .subheading;
  }

  h4 {
    font-weight: bold;
    font-size: 1em;
    line-height: 160%;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }

  p {
    @extend .paragraph__regular;

    a {
      color: $teal;
      text-decoration: underline;
    }
  }

  .contained-button--about {
    margin-top: 2rem;
    @extend .button;
    @extend .contained-button;
  }

  @media #{$breakpoint-not-small} {
    margin-bottom: 8rem;
  }
}
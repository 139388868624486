footer {
  z-index: $footer-zindex;
}

.footer {
  padding: 2rem 0 3rem;
  background-color: $charcoal;
  color: white;
}

.footer__content {
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
  line-height: 1.6;
  color: white;
  margin-bottom: 3rem;
}

.footer__links {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-bottom: 2rem;
}

.footer__text-links {
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: white;
    font-weight: 600;
    padding-bottom: 0.0625rem;
    margin-bottom: 2rem;
    border-bottom: solid 1px $charcoal;
    transition: all 0.24s ease;

    &:hover {
      color: $light-teal;
      border-bottom: solid 1px $light-teal;
    }

    &:focus {
      border-bottom: solid 1px;
    }
  }

  a:last-child {
    margin-bottom: 0;
  }
}

.footer__social-links {
  margin-bottom: 2rem;

  a:first-child {
    margin-right: 1.5rem;
  }
}

.footer__copyright {
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    margin-top: 2rem;
    width: 6.25rem;
  }
}

@media #{$breakpoint-not-small} {

  .footer__content {
    margin-bottom: 3rem;
  }

  .footer__links {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  .footer__text-links {
    flex-direction: row;

    a {
      margin: 0 3rem 0 0;
    }
  }

  .footer__social-links {
    margin: 0;
    width: 6.25rem;
  }

  .footer__copyright {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    a {
      margin-top: 0;
    }
  }
}

@media #{$breakpoint-large} {
  .footer__content {
    margin: 0 0 3rem;
    max-width: 46rem;
  }
}

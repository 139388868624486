.button {
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.0625rem;
  transition: all 0.24s ease;
  &:hover {
    cursor: pointer;
  }
}

.text--transform-none {
  text-transform: none;
}


.contained-button {
  display: inline-block;
  padding: 1rem 1.5rem;
  border-radius: 1.3rem 0 1.3rem 0;
  background-color: $teal;
  color: white;
  text-align: center;
  border-color: transparent;
  &:hover {
    border-radius: 0;
    background-color: $dark-teal;
    cursor: pointer;
  }
}

.button--text {
  border: none;
  background-color: transparent;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
}

.text__button {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  border: none;
  color: $teal;
  transform: translateX(0);
  &:hover {
    border: none;
    color: $dark-teal;
  }
  &:hover > .text__button--arrow {
    animation-name: bounce_right;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
}
.text__button--arrow {
  margin-left: 1rem;
}
@keyframes bounce_right {
   0% {
      transform: translateX(0);
   }
   50% {
     transform: translateX(1rem);
   }
   100% {
      transform: translateX(0);
   }
}

.search__box{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.5rem;
  margin: 1rem 0;
  border: 1px solid $grey;
  border-radius: 1.8rem;
  width: 100%;
  cursor: text;

  & input[type=text] {
    border: none;
    outline: none;
    max-width: 6rem;
    transition: max-width 0.24s ease;
  }

  & input[type=text]:focus {
    max-width: 12rem;
  }

  & button[type=submit] {
    border: none;
    color: $charcoal;
    background-color: white;
    cursor: pointer;
  }

  & button[type=submit]:hover {
    color: $teal;
  }
}

.search__box:focus-within {
  border-color: $teal;
}

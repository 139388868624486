:root {
  --reach-listbox: 1;
}

.dropdown-list__popover {
  display: block;
  position: absolute;
  min-width: 10rem;
  padding: 0;
  background: hsl(0, 0%, 100%);
  outline: none;
  border: none;
  z-index: $dropdown-menu-z-index;
}

.dropdown-list__popover:focus-within {
  box-shadow: 0 0 4px Highlight;
}

.dropdown-list__popover[hidden] {
  display: none;
}

.dropdown-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-list:focus {
  box-shadow: none;
  outline: none;
}

.dropdown-list__item {
  display: block;
  margin: 0;
  padding: 0;
  white-space: normal;
  user-select: none;
  color: $tundora;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  box-shadow: 0px -1px 0px #EEEEEE;
  padding: 0.625rem;
  cursor: pointer;
}

.dropdown-list__item[data-current-nav] {
  color: $teal;
  font-weight: bolder;
}

.dropdown-list__item[data-current-selected] {
  font-weight: bolder;
}

.dropdown-list__item[data-current-selected][data-confirming] {
  animation: flash 100ms;
  animation-iteration-count: 1;
}

.dropdown-list__item[aria-disabled="true"] {
  opacity: 0.5;
}


.dropdown [data-reach-listbox-button] {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 10px 2px;
  border: none;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  cursor: pointer;
  user-select: none;
  color: $tundora;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
}

.dropdown:hover > span {
  color: $teal;
}

.dropdown [data-reach-listbox-button][aria-disabled="true"] {
  opacity: 0.5;
}

.dropdown__arrow {
  margin-left: 1rem;
  display: block;
  font-size: 0.8em;
  @extend .fas;
  @extend .fa-chevron-down
}

@keyframes flash {
  0% {
    background: hsla(211, 81%, 36%, 1);
    color: hsl(0, 0%, 100%);
    opacity: 1;
  }
  50% {
    opacity: 0.5;
    background: inherit;
    color: inherit;
  }
  100% {
    background: hsla(211, 81%, 36%, 1);
    color: hsl(0, 0%, 100%);
    opacity: 1;
  }
}

@import "./page";
@import "./z_index";

.header__container {
  height: 5rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  color: $charcoal;
  z-index: $header-navigation-index;
  background-color: white;
  box-shadow: 0px 1px 0px $gallery-grey;
}

.header__top{
  width: 100%;
  max-width: $max-page-width;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  border-bottom: 1px solid $grey;
  padding: 1rem;
}

.header__logo{
  display: block;
  width: 5.2575rem;
}

.header__controls{
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;

  & .search__box{
    display: none;
    margin: 0;
  }
}

.header__join-us{
  color: $charcoal;
  font-size: 1rem;
  line-height: 160%;
  margin-right: 1rem;
}

.header__join-us:hover {
  color: $teal;
}


.header__menu-button{
  cursor: pointer;
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $charcoal;
  background-color: white;
}

.header__navigation {
  width: 100%;
  max-width: $max-page-width;
  background-color: white;
  display: none;
  padding: 0;
}

.header__navigation--open {
  position: absolute;
  top: 5rem;
  left: 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: $header-navigation-index;
}

.header__navigation-container {
  padding: 1rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.header__page-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.header__link{
  display: block;
  font-size: 1rem;
  line-height: 1.375rem;
  margin: 1rem 0;
  padding-left: 0;

  & a {
    color: $charcoal;
    font-weight: 400;
  }

  & a:hover {
    color: $teal;
  }
}

.header__link--active {
  & a {
    color: $teal;
  }
}

.header__feedback-link{
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: $charcoal;

  &:hover {
    color: $teal;
  }
}

@media #{$breakpoint-medium} {
  .header__top {
    padding: 1rem 1.5rem;
    height: auto;
  }

  .header__controls {
    & .search__box{
      display: flex;
    }
  }

  .header__navigation--open {
    align-items: flex-end;
    background-color: transparent;
  }

  .header__navigation-container {
    width: 100%;
    max-width: 24.43rem;
    background-color: white;
    align-items: flex-end;
    padding: 1rem 1.5rem;
  }

  .header__page-links {
    align-items: flex-end;
  }

  .search__box{
    display: none;
  }
}

@media #{$breakpoint-large} {

  .header__container {
    height: 9.715625rem;
  }

  .header__top {
    padding: 1rem 0;
    height: auto;
  }

  .header__logo {
    width: 7.5rem;
  }

  .header__menu-button {
    display: none;
  }

  .header__navigation {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    padding: 1rem 0 0 0;
    margin: 0 2rem;
    background-color: white;

    & .search__box {
      display: none;
    }
  }

  .header__navigation-container {
    width: 100%;
    max-width: none;
    background-color: white;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
  }

  .header__page-links {
    flex-direction: row;
  }

  .header__link {
    margin: 0;

    & a {
      display: block;
      margin: 0 2rem 0 0;
      padding: 0 0 1rem 0;
      font-weight: 600;
    }

    & a:focus {
      color: $teal;
    }

    &:hover {
      & a {
        color: $teal;
        padding: 0 0 0.8rem 0;
        border-bottom: 0.2rem solid $teal;
      }
    }
  }

  .header__feedback-link {
    margin: 0;
    padding: 0 0 1rem 0;
    font-weight: 600;

    &:hover {
      padding: 0 0 1rem 0;
      border-bottom: 0.2rem solid $teal;
    }

    &:focus {
      color: $teal;
    }
    
    display: flex;
    align-items: center
    
  }

  .header__menu-button{
    display: none;
  }

  .header__controls {
    & .search__box {
      display: flex;
    }
  }
}
$card-padding: 1.5rem;

.modal {
  width: calc(100% - 32px);
  max-width: 726px;
  display: none;
  background-color: $jagged-ice;
  position: fixed;
  left: 0;
  right: 0;
  padding: 0;
  max-height: 70%;
  margin: auto;
  overflow-y: auto;
  border-radius: 2px;
  z-index: 9999;
  top: 4%;
  opacity: 0;
  will-change: top, opacity;
}

.modal--white {
  width: 90vw;
  max-width: 46rem;
  margin: 10vh auto;
  background-color: white;
  padding: 2rem;
  outline: none;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.24));
  border-radius: 0.1875rem;

  h1 {
    color: $teal;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
}

.modal__text {
  color: $charcoal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
  padding-bottom: 1rem;
  width: 100%;
  text-align: left;

  p {
    margin: 1rem 0;
  }
}

.modal__text a {
  color: $dark-teal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.6;
  cursor: pointer;
}

.modal__text a:hover {
  text-decoration: underline;
}

@media #{$breakpoint-medium} {
  .modal {
    width: calc(100% - 48px);
  }
}

@media #{$breakpoint-large} {
  .modal {
    width: 726px;
  }
}

.modal {
  color: $charcoal;
  padding: $card-padding;
}

@media only screen and (max-width: 601px) {
  .modal:not(.no-fullscreen) {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100vw;
    height: 100vh;
    padding-top: 50px;
    padding-bottom: 100px;
    max-width: 100vw;
    max-height: 100vh;
  }
}

.link {
  display: inline-block;
  font-weight: 400;
  line-height: 1.6;
  transition: all 0.24s ease;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.link--underline {
  text-decoration: underline;
}

.text__link {
  color: $charcoal;
  text-decoration: underline;

  &:hover {
    color: $teal;
    text-decoration-thickness: 2px;
  }
}

.text__link--teal {
  color: $teal;
  font-weight: 600;

  &:hover {
    color: $dark-teal;
  }
}

.text__link--teal--underline {
  color: $teal;
  font-weight: 600;
  text-decoration: underline;

  &:hover {
    color: $dark-teal;
  }
}
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .current {
    color: $teal;
    font-weight: 700;
    font-style: normal;
  }
  a {
    color: $charcoal;
    text-decoration: underline;
    font-weight: 400;
    font-size: 1rem;
    &:hover, &:focus {
      color: $teal;
      font-weight: 700;
    }
  }
  .next_page, .previous_page {
    @extend .button;
    @extend .contained-button;
    font-weight: 700;
    letter-spacing: 0.0625rem;
    text-decoration: none;
    &:focus, &:hover {
      color: white;
    }
    &.disabled {
      background-color: $grey;
      pointer-events: none;
      color: $charcoal;
    }
  }
  .next_page {
    &:after {
      @extend .fas;
      content: "\f061";
      padding-left: 0.75rem;
    }
  }
  .previous_page {
    &:before {
      @extend .fas;
      content: "\f060";
      padding-right: 0.75rem;
    }
  }
}

.gap, .pagination em, .pagination a {
  display: none;
}

@media #{$breakpoint-not-small} {
  .gap, .pagination em, .pagination a {
    display: inline-block;
  }
  .next_page {
    margin-left: 2.5rem;
  }
  .previous_page {
    margin-right: 2.5rem;
  }
}
.join-us_container {
  background-color: $charcoal;
}

.join-us_content {
  color: white;
  align-self: center;
  width: 100%;
  max-width: 96rem;
  padding: 2rem;
}

.join-us_form {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.join-us_input-field{
  width: 100%;
}

.join-us_input-field:not(:first-child){
  padding-top: 1rem;
}

.join-us_input__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.join-us_title {
  font-weight: 700;
  padding-bottom: 1rem;
}

.join-us_description {
  margin-bottom: 2rem;
}

.join-us_submit {
  margin-bottom: 0rem;
  max-width: 9rem;
}

.join-us_feedback {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  font-weight: 700;
}

@media #{$breakpoint-not-small} {
  .join-us_input-field:not(:first-child){
    padding-top: 0;
  }
}

@media #{$breakpoint-medium} {
  .join-us_input-field{
    width: 50%;
  }

  .join-us_input-field:nth-child(odd){
    padding-right: 1.5rem;
  }

  .join-us_input-field:nth-child(odd):not(:last-child){
    padding-bottom: 1rem;
  }
}

@media #{$breakpoint-large} {
  .join-us_input-field{
    width: calc(100% / 3);
  }

  .join-us_input-field:not(:last-child){
    padding-right: 2rem;
  }
}
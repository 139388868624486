.article__layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 1.5rem;
  max-width: 76.5rem;
  margin-left: auto;
  margin-right: auto;

  .article__layout--left {
    width: 15rem;
    display: none;
    position: sticky;
    top: 17.403125rem;
    margin-bottom: 8rem;
    overflow-y: auto;
  }

  .article__layout--middle {
    max-width: 100%;
    width: 100%;
  }

  .article__layout--right {
    width: 100%;
    margin-bottom: 6rem;
  }

  .statistic-section {
    h3 {
      font-size: 1.5rem;
      padding-bottom: 2rem;
      padding-top: 2rem;
      width: 100%;
    }

    h4 {
      font-size: 1.25rem;
      padding-bottom: 0.25rem;
    }

    .statistic {
      padding-bottom: 2rem;

      table {
        width: 100%;

        tr {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 0.125rem;
          padding-bottom: 0.125rem;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;
          }

          th {
            text-align: start;
            font-size: 1rem;
            font-weight: normal;
            width: 75%;
          }

          .definition-term__link {
            font-size: inherit;
            font-weight: inherit;
            text-align: start;
          }
        }
      }
    }
  }
}

.article-upload {
  padding-top: 1.5rem;
}

.article-content__end-linebreak {
  margin-top: 5rem;
  color: $grey;
  border-bottom: solid 1px;
}

.article-content__wrapper {
  margin-right: 0rem;
  margin-left: 0rem;
  margin-bottom: 6rem;
  color: $charcoal;

  .text-content {
    line-height: 1.625;
    margin-top: 2.25rem;

    &:first-of-type {
      margin-top: 0;
    }

    ul {
      padding-left: 1.25rem;
    }

    li {
      margin-bottom: 0;
      margin-left: 0;
    }

    a {
      color: #0095ac;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  h4 {
    font-size: 1.25rem;
    scroll-margin-top: 14.375rem;
  }

  .article-content__title {
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 120%;
    letter-spacing: 0.5px;
    margin-bottom: 1.5rem;
  }

  .article-subheading {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 120%;
    letter-spacing: 0.5px;
    text-decoration: none;
    margin-bottom: 0.5rem;
    margin-top: 0.75rem;
    scroll-margin-top: 14.375rem;
  }
}

@media #{$breakpoint-not-small} {
  .statistic-section {
    display: flex;
    flex-wrap: wrap;

    .statistic {
      width: 50%;
    }
  }
}

@media #{$breakpoint-large} {
  .article__layout--right {
    max-width: 15rem;
  }

  .article__layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    width: auto;
    justify-content: center;
    margin-top: 3.5rem;

    .article__layout--left {
      display: block;
    }

    .article__layout--middle {
      max-width: 42.5rem;
    }
  }

  .article-content__wrapper {
    max-width: 42.5rem;
    margin-right: 2rem;
    margin-left: 2rem;
    margin-bottom: 8rem;
  }
}
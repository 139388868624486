$teal: #157E77;
$light-teal: #8FCECA;
$jagged-ice: #BCE9E6;
$dark-teal: #0D4A46;
$alabaster-grey: #F8F8F8;
$white-smoke: #F5F5F5;
$grey: #DEDEDE;
$silver: #CDCDCD;
$tundora: #424242;
$charcoal: #373737;
$black-0-7: rgba(0, 0, 0, 0.7);
$black-0-8: rgba(0, 0, 0, 0.8);
$gallery-grey: #EEEEEE;
$light-grey: #A0A3B7;
$peach: #ffb4b0;
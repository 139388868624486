.main-background-image {
  background-image: none;
}

.stick-to-top {
  position: fixed;
  top: 0;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .main-background-image {
    background-size: 100vw auto;
    background-position: top;
  }
}

@media #{$breakpoint-large} {
  .width--two-thirds--large {
    width: calc(2 / 3 * 100%);
  }
}
.skip-navigation {
  display: none;
}

@media #{$breakpoint-large} {
  .skip-navigation {
    width: 100%;
    height: 0px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 9.84rem;
    background-color: white;

    &:focus-within {
      height:auto;
    }
  }

  .skip-navigation__container {
    max-width: $max-page-width;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    padding: 0.25rem 0;

    & a {
      line-height: 1.5625rem;
      text-decoration: none;
      color: $charcoal;
    }

    & a:hover {
      color: $teal;
    }

    & a:focus {
      text-decoration: underline;
      color: $teal;
    }
  }
}

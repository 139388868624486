.breadcrumbs{
  width: 100%;
  padding: 1.25rem 1rem;
  position: relative;
  background: white;
  box-shadow: 0px 1px 0px $gallery-grey;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  z-index: $breadcrumbs;
}

.breadcrumbs__container {
  height: 5.7869rem;
}

.breadcrumbs__list {
  width: 100%;
  max-width: $max-page-width;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  margin: 0;
  padding: 0;
  list-style: none;
}

.breadcrumbs__container::-webkit-scrollbar {
  display: none;
}

.breadcrumbs--fixed {
  position: fixed;
  top: 5rem;
}

.breadcrumb-item {
  color: $tundora;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
}

.breadcrumb-item:hover {
  color: $teal;
}

.breadcrumb-item:focus-within {
  color: $teal;

  .breadcrumb-item__text {
    text-decoration: underline;
  }
}

.breadcrumb-item__text {
  color: inherit;
  font-weight: 400;
  font-size: 1rem;
  line-height: 160%;
  width: fit-content;
  white-space: nowrap;
  margin: 0;
}

.breadcrumb-item__text:visited {
  color: inherit;
}

.breadcrumb-item__icon--right {
  @extend .fas;
  @extend .fa-chevron-right;
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0 1.1rem;
  text-decoration: none;
}
.breadcrumb-item:first-child {
  .breadcrumb-item__icon--right {
    display: none;
  }
}

@media #{$breakpoint-large} {
  .breadcrumbs--fixed {
    position: fixed;
    top: 9.715625rem;
  }

  .breadcrumbs__container {
    height: 4.1875rem;
  }
}
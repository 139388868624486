.content-container {
  display: flex;
  justify-content: center;
  .content {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
  }
  @media #{$breakpoint-not-small} {
    .content {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  @media #{$breakpoint-large} {
    .content {
      max-width: $max-page-width;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@import "_colors";
@import "_buttons";
@import "_forms";
@import "_header";

// new

.flex-1-0-auto {
  flex: 1 0 auto;
}

.h-1-75rem {
  height: 1.75rem;
}

.mv-4-125rem {
  margin-top: 4.125rem;
  margin-bottom: 4.125rem;
}

.mt-3rem {
  margin-top: 3rem;
}

.mt-6rem {
  margin-top: 6rem;
}

.mb1-75rem {
  margin-bottom: 1.75rem;
}

.mb-3rem {
  margin-bottom: 3rem;
}

.mb-4rem {
  margin-bottom: 4rem;
}

.mb-6rem {
  margin-bottom: 6rem;
}

.pb1-5rem {
  padding-bottom: 1.5rem;
}

.pb2-75rem {
  padding-bottom: 2.75rem;
}

.border-grey {
  border: solid 1px $grey;
}

.b--grey {
  border-color: $grey;
}

.pt-1-5rem {
  padding-top: 1.5rem;
}

.pb-3rem {
  padding-bottom: 3rem;
}

.br-3 {
  border-radius: 3px;
}

.mw-40rem {
  max-width: 40rem;
}

.lh-1-6rem {
  line-height: 1.6rem;
}

// old

.h6 {
  height: 24rem;
}

.h7 {
  height: 40rem;
}

.h4-plus {
  height: 5.5rem;
}

.mw5-plus {
  max-width: 24rem;
}

.mw46-rem {
  max-width: 46rem;
}

.pt1-25rem {
  padding-top: 1.25rem;
}

.ph2-5 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.pa4-5 {
  padding: 2.5rem;
}

.ph4-5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.pt4-5 {
  padding-top: 2.5rem;
}

.pb4-5 {
  padding-bottom: 2.5rem;
}

.mb0-75rem {
  margin-bottom: 0.75rem;
}

.mb1-5rem {
  margin-bottom: 1.5rem;
}

.mb3-625rem {
  margin-bottom: 3.625rem;
}

.mb6rem {
  margin-bottom: 6rem;
}

.mt1-5rem {
  margin-top: 1.5rem;
}

.mt2-5rem {
  margin-top: 2.5rem;
}

.mt3-75rem {
  margin-top: 3.75rem;
}
.mw-12-3rem {
  max-width: 12.3125rem;
}

.w-10rem-ns {
  @media only screen and (min-width: 30em) {
    width: 10rem !important;
  }
}

.w-15rem-ns {
  @media only screen and (min-width: 30em) {
    width: 15rem !important;
  }
}

.w-6rem {
  width: 6rem;
}

.mw-6-375rem {
  max-width: 6.375rem;
}

.br1-5 {
  border-radius: 0.125rem;
}

.opacity-transition {
  transition: opacity ease-in-out 0.15s;
}

.events-all {
  pointer-events: all;
}

.events-none {
  pointer-events: none;
}

.z-neg-1 {
  z-index: -1;
}

.grow-on-hover {
  transition: transform ease-in-out 1s;
}

.grow-on-hover-container:hover .grow-on-hover {
  transform: scale(1.05);
}
.f1-plus-l {
  @media only screen and (min-width: 64em) {
    font-size: 54px !important;
  }
}
.f-0-875rem {
  font-size: 0.875rem;
}
.expandable {
  transition: max-height 0.5s ease-in-out;
  max-height: 0;
  overflow: hidden;
  &[expanded="true"] {
    max-height: 2000px;
  }
}

.box-decoration-break-clone {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

.lh-1-75 {
  line-height: 1.75;
}
.lh-1-5 {
  line-height: 1.5;
}

.lh-1-6 {
  line-height: 1.6;
}

.lh-2 {
  line-height: 2;
}

.enquiry-close-position {
  top: 50px;
  right: 25px;
  @media (max-width: 640px) {
    top: 10px;
    right: 10px;
  }
}

.pa-1-4rem {
  padding: 1.4rem;
}

.pa-1-5rem {
  padding: 1.5rem;
}

.ph-1-5rem {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.ph-0-75rem {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.pt-0-6rem {
  padding-top: 0.6rem;
}

.pt-1-125rem {
  padding-top: 1.125rem;
}

.pb-2-25rem {
  padding-bottom: 2.25rem;
}

.pa-24px {
  padding: 24px;
}

@media screen and (min-width: 30em) {
  .w-32-ns {
    width: 32%;
  }
}

.bg--transparent {
  background-color: transparent;
}

.tc-fill-white {
  -webkit-text-fill-color: white;
}

.ml-1-5rem {
  margin-left: 1.5rem;
}
